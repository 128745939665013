'use client'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Icons } from '@/components/icons'
import { env } from '@/env.mjs'
import { Loader2 } from 'lucide-react'
import { createClient } from '@/utils/supabase/client'
import Cookies from 'js-cookie';

const SignInWithGoogle = () => {
  const [loading, setLoading] = useState(false)
  const supabase = createClient()

  async function handleGoogleSignIn() {
    setLoading(true)
    const referralCode = Cookies.get('referral_code');

    try {
      // redirects to /sign-in to check if the user has MFA setup (handled in SignInLayout.tsx)
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: env.NEXT_PUBLIC_APP_URL + '/auth/callback?returnPath=sign-in-mfa&referralCode=' + referralCode,
        },
      })
      if (error) throw error
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={handleGoogleSignIn}
      size="sm"
      variant="secondary"
    >
     {loading ? <Loader2 className='mr-2 h-4 w-4 animate-spin'/> : <Icons.google className='mr-2 h-4 w-4'/>}  Continue with Google
    </Button>
  )
}

export default SignInWithGoogle
